html {
  box-sizing: border-box;
}

body {
  background: #fff;
  color: #0b2641;
  font-family:'Open Sans', sans-serif;
}
a.decorated{
font-family: "Open Sans", sans-serif;
  display: inline-block;
  border: none;
  padding: 0px;
  margin: 0px;
  background: none;
  cursor: pointer;
  appearance: none;
  font-size: 16px;
  text-transform: none;
  font-weight: 600;
  text-decoration: underline 2px rgb(37, 179, 235);
  text-underline-offset: 5px;
  color: rgb(11, 38, 65);
}

*,
*:before,
*:after {
  box-sizing: inherit;
}